import {HomeRoute} from "../../../Routes/HomeRoute";
import Link from "next/link";
import {LogoWithText} from "../../common/Logo/LogoWithText";
import {LogoWithWhiteText} from "../../common/Logo/LogoWithWhiteText";
import RouteNames from "../../../Routes/RouteNames";
import {useRouter} from "next/router";
import Image from "next/image";
import FooterVersionNumberComponent from "../../changelog/FooterVersionNumberComponent";

export interface IFooterProps {
  backgroundColor: string;
  titleColor: string;
  textColor: string;
  whiteLogo?: boolean;
}

export const Footer = ({
  backgroundColor,
  textColor,
  titleColor,
  whiteLogo = false,
}: IFooterProps) => {
  const router = useRouter();

  const handleClick = (e: any, path: string) => {
    e.preventDefault;
    router.push(path);
  };
  return (
    <div
      style={{ color: titleColor, backgroundColor: backgroundColor }}
      className="py-16 px-4 lg:px-52 text-black"
    >
      <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-8">
        <div className="col-span-1 flex flex-col gap-8 justify-center items-center lg:justify-end lg:items-start">
          {whiteLogo ? <LogoWithWhiteText /> : <LogoWithText />}
          <div className="flex items-center gap-2">
            <div className="relative  flex flex-col items-center justify-center gap-8">
              {/* <span className=" text-center">
                Download PhotoJoiner Mobile App
              </span> */}
              <div className="flex flex-col lg:flex-row justify-center items-center w-full pb-4">
                <Link
                  className=" inline-block  aspect-[234.75/71.41]"
                  href="https://play.google.com/store/apps/details?id=com.freeconvert.collagemaker"
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) =>
                    handleClick(
                      e,
                      "https://play.google.com/store/apps/details?id=com.freeconvert.collagemaker"
                    )
                  }
                >
                  <Image
                    width={150}
                    height={60}
                    src="/static/download-on-the-play-store.svg"
                    alt="Download app form play store"
                  />
                </Link>
                <span className="p-2 lg:p-4"></span>
                <Link
                  href="https://apps.apple.com/us/app/collage-maker-photojoiner/id1587802950"
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) =>
                    handleClick(
                      e,
                      "https://apps.apple.com/us/app/collage-maker-photojoiner/id1587802950"
                    )
                  }
                >
                  <Image
                    className=""
                    width={150}
                    height={60}
                    src="/static/download-on-the-app-store.svg"
                    alt="Download app form app store"
                  />
                </Link>
              </div>
            </div>
            {/* <a className="cursor-pointer">
                            <div
                                className="w-8 h-8 rounded-full flex items-center justify-center"
                                style={{ border: `1px solid ${textColor}` }}
                            >
                                <Pinterest fill={textColor} />
                            </div>
                        </a>
                        <a className="cursor-pointer">
                            <div
                                className="w-8 h-8 rounded-full  flex items-center justify-center"
                                style={{ border: `1px solid ${textColor}` }}
                            >
                                <Twitter fill={textColor} />
                            </div>
                        </a>
                        <a className="cursor-pointer">
                            <div
                                className="w-8 h-8 rounded-full flex items-center justify-center"
                                style={{ border: `1px solid ${textColor}` }}
                            >
                                <Facebook fill={textColor} />
                            </div>
                        </a>
                        <a className="cursor-pointer">
                            <div
                                className="w-8 h-8 rounded-full flex items-center justify-center"
                                style={{ border: `1px solid ${textColor}` }}
                            >
                                <Instagram fill={textColor} />
                            </div>
                        </a>
                        <a className="cursor-pointer">
                            <div
                                className="w-8 h-8 rounded-full flex items-center justify-center"
                                style={{ border: `1px solid ${textColor}` }}
                            >
                                <Linkedin fill={textColor} />
                            </div>
                        </a>
                        <a className="cursor-pointer">
                            <div
                                className="w-8 h-8 rounded-full flex items-center justify-center"
                                style={{ border: `1px solid ${textColor}` }}
                            >
                                <Youtube fill={textColor} />
                            </div>
                        </a> */}
          </div>
        </div>
        <div className="col-span-2">
          <div className="flex flex-row pt-12 gap-12 justify-center items-center lg:items-end lg:justify-end">
            <div
              className="flex flex-col gap-4 justify-center items-start"
              style={{ color: textColor }}
            >
              <div className="font-bold">Products</div>

              <Link
                href={RouteNames.featureStitchPictures}
                onClick={(e) =>
                  handleClick(e, RouteNames.featureStitchPictures)
                }
              >
                Photo Joiner
              </Link>

              <a
                href={RouteNames.featureCollageMaker}
                onClick={(e) => handleClick(e, RouteNames.featureCollageMaker)}
              >
                Collage Maker
              </a>
            </div>
            <div
              className="flex flex-col gap-4 justify-center items-start -ml-4 lg:ml-0"
              style={{ color: textColor }}
            >
              <div className="font-bold">About us</div>

              <a
                href={RouteNames.contactUs}
                onClick={(e) => handleClick(e, RouteNames.contactUs)}
              >
                Contact Us
              </a>

              <a
                onClick={(e) => {
                  e.preventDefault();
                  router.push("https://old.photojoiner.com/");
                }}
                href={"https://old.photojoiner.com/"}
              >
                Old Version
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="pt-8 mt-16 flex flex-col lg:flex-row justify-between gap-4"
        style={{ borderTop: "1px solid #EEEFF2" }}
      >
        <div className="flex items-center justify-center gap-1">
          <span style={{ color: textColor }}>Language:</span>
          <span style={{ color: textColor }}> English</span>
        </div>
        <div className="gap-4" style={{ color: textColor }}>
          <div className="flex flex-col lg:flex-row gap-2 items-center lg:items-start">
            <div className="flex items-center gap-2">
              <HomeRoute>
                <div className="font-medium">
                  &copy;{` 2024, Photojoiner.`}{" "}
                </div>
              </HomeRoute>
              <FooterVersionNumberComponent />
            </div>
            <div className="flex gap-2 items-center">
              <Link href={RouteNames.tearmsAndConditions}>
                Terms and Conditions
              </Link>
              <span>{" | "}</span>
              <Link href={RouteNames.privacyPloicy}>Privacy</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
