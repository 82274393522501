import Link from "next/link";
import RouteNames from "./RouteNames";

interface IHomeRouteProps {
  children: React.ReactNode;
}
export const HomeRoute = (props: IHomeRouteProps) => {
  return (
    <div>
      <Link href={RouteNames.home}>{props.children}</Link>
    </div>
  );
};
