export default class RouteNames {
    static home: string = "/";
    static collageEditor: string = "/editor/collage-editor";
    static collageEditorStitch: string =
        "/editor/collage-editor/?ref=photo-joiner";
    static tearmsAndConditions: string = "/terms-and-conditions";
    static privacyPloicy: string = "/privacy-policy";
    static featureCollageMaker: string = "/features/collage-maker";
    static featureStitchPictures: string = "/features/photo-joiner";
    static contactUs: string = "/contact-us";
    static changeLog: string = "/changelog";
    static oldPhotojoiner: string = "https://old.photojoiner.com";


    static asList = [
        RouteNames.home,
        RouteNames.collageEditor,
        RouteNames.collageEditorStitch,
        RouteNames.tearmsAndConditions,
        RouteNames.privacyPloicy,
        RouteNames.featureCollageMaker,
        RouteNames.featureStitchPictures,
        RouteNames.contactUs,
        RouteNames.changeLog,
        RouteNames.oldPhotojoiner
    ]
}
