import React, {useEffect} from 'react';
import Link from "next/link";

const FooterVersionNumberComponent = () => {

    const [versionNumber, setVersionNumber] = React.useState("1.0.0");

    useEffect(() => {
        void fetchVersionNumber();
    }, []);
    async function  fetchVersionNumber(): Promise<string> {
     const res = await    fetch("/web/version.json");
       const data = await  res.json();
        setVersionNumber(data.version ?? "1.0.0");
       return  data.version ?? "";
    }
    return (
        <div>
            <Link href="/changelog" className='underline text-sm'>v{versionNumber}</Link>
        </div>
    );
};

export default FooterVersionNumberComponent;