import {HomeRoute} from "../../../Routes/HomeRoute";

export interface ILogoWithWhiteTextProps {}

export const LogoWithWhiteText = ({}: ILogoWithWhiteTextProps) => {
    return (
       <HomeRoute>
            <div className="cursor-pointer flex items-center gap-4 w-52 aspect-[537/90]">
            <img className="w-full h-full" src="/static/photojoiner-logo-white-text.png" alt="PhotoJoiner logo"/>
        </div>
       </HomeRoute>
    );
};
