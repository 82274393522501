import {Fragment, useState} from "react";

import Link from "next/link";
import {LogoWithText} from "../../common/Logo/LogoWithText";
import RouteNames from "../../../Routes/RouteNames";
import {Transition} from "@headlessui/react";
import {WhiteLogoWithText} from "../../common/Logo/WhiteLogoWithText";
import {useRouter} from "next/router";
import Image from "next/image";

export interface INavbarProps {
  backgroundColor: string;
  whiteLogo?: boolean;
  textColor?: string;
}

export const Navbar = ({
  backgroundColor,
  whiteLogo = false,
  textColor = "#000",
}: INavbarProps) => {
  const router = useRouter();
  const navItems = [
    {
      label: "Collage Maker",
      href: RouteNames.featureCollageMaker,
    },
    {
      label: "Photo Joiner",
      href: RouteNames.featureStitchPictures,
    },
    {
      label: "Contact Us",
      href: RouteNames.contactUs,
    },
    {
      label: "Old Version",
      href: RouteNames.oldPhotojoiner,
    },
  ];
  const [isOpen, setIsOpen] = useState(false);

  const button = (
    <div>
      <div className="-mr-2 flex lg:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className="bg-transparent hover:bg-gray-200 inline-flex items-center justify-center
                    p-2 rounded-md text-gray-800   focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          {!isOpen ? (
            <svg
              className="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          ) : (
            <svg
              className="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
  return (
    <div
      style={{ color: textColor, backgroundColor }}
      className="absolute left-0 right-0 top-0 py-4 px-4 xl:px-52 flex justify-between z-20 border-b border-solid border-[#E9EAEB]"
    >
      <div className="">
        {whiteLogo ? <WhiteLogoWithText /> : <LogoWithText />}
      </div>

      {button}

      <div className={`${isOpen ? "block" : "hidden"} absolute right-0 top-0`}>
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-100"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="lg:hidden h-screen  w-screen " id="mobile-menu">
              <div className="flex flex-row">
                <div
                  onClick={() => setIsOpen(false)}
                  className="w-full h-screen"
                ></div>
                <div className="shadow-2xl  h-screen w-72 bg-white text-black  ring-1 ring-black ring-opacity-5">
                  <div className="flex flex-row justify-between w-full">
                    <div
                      onClick={() => {
                        router.push("/");
                        setIsOpen(false);
                      }}
                      className=" cursor-pointer flex items-center p-4 w-36"
                    >
                      <Image
                        src="/static/photojoiner-logo-black-text.png"
                        alt="PhotoJoiner logo"
                        width={200}
                        height={60}
                      />
                    </div>
                    <div className="p-4">{button}</div>
                  </div>
                  {navItems.map((item, index) => (
                    <NavBarItem
                      key={navItems[index].href}
                      href={navItems[index].href}
                      label={navItems[index].label}
                      currentRoute={router.pathname}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </Transition>
      </div>
      <div className="hidden lg:flex flex-col lg:flex-row items-center gap-2 lg:gap-8">
        {navItems.map((item, index) => (
          <NavBarItem
            key={navItems[index].href}
            href={navItems[index].href}
            label={navItems[index].label}
            currentRoute={router.pathname}
          />
        ))}
      </div>
    </div>
  );
};

interface INavBarItemProps {
    label: string;
    href: string;
    currentRoute: string;
}

const NavBarItem = (props: INavBarItemProps) => {
    var selected: boolean =
        `${props.currentRoute}/` === props.href ||
        `${props.currentRoute}` === props.href;
    const router = useRouter();
    return (
      <div
        className={`${
          selected ? " underline underline-offset-4 " : ""
        } block px-8 py-3  lg:px-1 lg:py-2 lg:rounded-md text-base`}
      >
        {props.href.startsWith("/") ? (
          <Link href={props.href}>{props.label}</Link>
        ) : (
          <Link
            onClick={(e) => {
              e.preventDefault();
              router.push(props.href);
            }}
            href={props.href}
          >
            {props.label}
          </Link>
        )}
      </div>
    );
};
