import {HomeRoute} from "../../../Routes/HomeRoute";
import Image from "next/image";

export interface ILogoWithTextProps {}

export const LogoWithText = ({}: ILogoWithTextProps) => {
    return (
        <HomeRoute>
  <div className="cursor-pointer flex items-center gap-4 w-52 ">
            <Image  width={200} height={60} src="/static/photojoiner-logo-black-text.png" alt="PhotoJoiner logo"/>
        </div>
        </HomeRoute>
      
    );
};
